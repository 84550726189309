<template>
  <div class="input-data">
    <div>
      <section>
        <div class="is-flex is-align-items-start is-justify-content-space-between is-flex-wrap-wrap">
          <div class="is-flex is-flex-wrap-wrap">
            <sg-select :items="[{block_name: $t('all'), prepend: false},...selectedFarm.blocks]"
                       type="is-secondary is-light"
                       :prepend="$t('farm.block')"
                       class="mb-2 mr-2"
                       label="block_name"
                       v-model="selectedBlock"
                       :placeholder="`${$t('select')} ${$t('farm.block')}`"/>

            <b-datepicker
                class="mb-2 mr-2"
                :placeholder="$t('date')"
                icon="calendar-text"
                locale="id-ID"
                v-model="selectedDate"
                editable/>
          </div>

          <b-button type="is-text" class="has-text-info has-shadow mb-2" @click="resetForm()" icon-left="sync">
            Reset Form
          </b-button>
        </div>

        <div class="b-table custom-table" v-if="filteredData.length">
          <div class="table-wrapper has-sticky-header" :class="{embed: embed}">
            <table class="table is-bordered">
              <thead>
              <tr class="header-row">
                <th class="has-text-centered" rowspan="3">{{ $t('farm.pond') }}</th>
                <th rowspan="3">{{ $t('time') }}</th>
                <th rowspan="3" style="min-width: 180px">{{ $t('note.note') }}</th>
                <th style="min-width: 280px" :colspan="selectedProducts.length">{{ $t('warehouse.product_name') }}</th>
              </tr>
              <tr>
                <th class="p-0" v-for="(item, key) in selectedProducts" :key="key" style="min-width: 200px">
                  <div class="is-flex is-align-items-center is-justify-content-space-between" style="background-color: #F8F8F8">
                    <b-field class="m-0 w-100">
                      <b-autocomplete
                          append-to-body
                          open-on-focus
                          keep-first
                          select-on-click-outside
                          :data="filteredProduct"
                          :placeholder="$t('search')"
                          field="product_name"
                          v-model="item.product_name"
                          @typing="searchProduct"
                          class="w-100"
                          style="min-width: 150px"
                          @select="option => handleSelectProduct(option, item)"/>

                      <p class="control">
                        <sg-select type="is-secondary is-light" no-outline expanded :items="unit" v-if="item.product"
                                   class="select-unit" append-to-body
                                   :disabled="!!item.product.warehouse_id"
                                   v-model="item.product.unit" :placeholder="`Unit`"></sg-select>
                      </p>
                    </b-field>
                    <div class="is-flex">
                      <b-button type="is-primary" icon-left="plus" size="is-small"
                                @click="addProduct(item, key + 1)"></b-button>
                      <b-button type="is-primary" icon-left="minus" size="is-small" class="mx-2"
                                @click="deleteProduct(key)"
                                :disabled="selectedProducts.length === 1"></b-button>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th class="p-0" v-for="(item, key) in selectedProducts" :key="key" style="min-width: 200px">
                  <b-field class="m-0">
                    <b-select :disabled="item.product && item.product.warehouse_id"
                              v-model.number="item.category_id"
                              :placeholder="$t('category')">
                      <option :value="6">{{ $t('warehouse.probiotics') }}</option>
                      <option :value="3">{{ $t('warehouse.chemical') }}</option>
                    </b-select>
                  </b-field>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in filteredData" :key="`data-${key}`">
                <td class="has-text-centered is-sticky">
                  <div class="is-flex is-justify-content-space-between is-align-items-center">
                    {{ item.pond.name }}
                    <div class="is-flex">
                      <b-button type="is-primary" icon-left="plus" size="is-small" class="ml-4 mr-2"
                                @click="addData(item, item.key + 1)"></b-button>
                      <b-button type="is-primary" icon-left="minus" size="is-small" @click="deleteData(item.key)"
                                :disabled="totalPondData(item) === 1"></b-button>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                  <b-field class="m-0">
                    <b-timepicker ref="input-form" class="input-form"
                                  @focus="handleFocus((key * maxInputForm))"
                                  @keydown.native.prevent.right="handleArrowRight"
                                  @keydown.native.prevent.left="handleArrowLeft"
                                  @keydown.native.prevent.down="handleArrowDown"
                                  @keydown.native.prevent.up="handleArrowUp"
                                  :placeholder="$t('time')"
                                  icon="calendar-text"
                                  locale="id-ID"
                                  append-to-body
                                  editable
                                  style="min-width: 120px"
                                  v-model="item.time"></b-timepicker>
                  </b-field>
                </td>
                <td class="p-0">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 1)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             :placeholder="`Input ${$t('note.note')}`" expanded v-model="item.desc"></b-input>
                  </b-field>
                </td>
                <td class="p-0" v-for="(product, productKey) in selectedProducts" :key="productKey">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 2 + productKey)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             placeholder="0.0" expanded type="number" step="any"
                             v-model.number="item.qty[productKey]"></b-input>
                  </b-field>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="has-text-danger" v-else>No Ponds have been stocked yet <span
            v-if="selectedBlock && selectedBlock.block_id">on Block {{ selectedBlock.block_name }}</span></p>
      </section>

      <footer class="is-flex is-justify-content-flex-end mt-3" v-if="filteredData.length">
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>

    <b-modal
        v-model="warehouseEmptyModal"
        has-modal-card
        :destroy-on-hide="false"
        aria-role="dialog"
        class="modal-center"
        :width="480"
        aria-modal>
      <template #default>
        <div class="card">
          <div class="card-content has-text-centered">
            <p class="is-size-5 has-text-weight-bold">{{ $t('warehouse.feed_empty') }}</p>

            <div class="is-flex is-justify-content-center mt-5">
              <b-button tag="router-link" :to="`/farm/${selectedFarm.farm_id}/warehouse`" type="is-primary"
                        class="ml-3">{{ $t('add') }} {{ $t('warehouse.product') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InputProbioticsBulk",
  components: {SgSelect},
  computed: {
    ...mapGetters('farm', [
      'unit',
    ]),

    date() {
      return this.$moment().set({
        hours: 7,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      }).toDate()
    },

    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.selectedBlock && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
      else return []
    },
    filteredPondsInput() {
      return this.selectedFarm.ponds.filter(e => e.status >= 1)
    },
    filteredData() {
      let data = this.data.filter(e => e.pond.status >= 2)
      if (this.selectedBlock && this.selectedBlock.block_id) data = data.filter(e => e.pond.block_id === this.selectedBlock.block_id)
      return data
    },

    embed() {
      return this.$route.query.output === 'embed'
    },
  },
  data: () => {
    return {
      filteredProduct: [],
      products: [],
      isFetchingProduct: false,

      // data: [{show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}],
      times: [],
      data: [],
      selectedBlock: null,
      selectedDate: null,

      selectedInput: 0,
      maxInput: 0,
      maxInputForm: 2,

      warehouseEmptyModal: false,

      selectedProducts: [],
    }
  },
  watch: {
    'selectedFarm.blocks'(value) {
      this.selectedBlock = value[0]
    },
    'selectedFarm.ponds'() {
      this.init()
    },
    data: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.probiotics-free.data', JSON.stringify(value))
      },
    },
    selectedDate: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.probiotics-free.date', JSON.stringify(value))
      },
    },
    selectedProducts: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.probiotics-free.products', JSON.stringify(value))
        if (value) {
          this.maxInputForm = 2 + value.length
          this.maxInput = this.$refs[`input-form`].length - 1
        }
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('farm', [
      'addWarehouse',
    ]),
    addData(item, key) {
      let newItem = this._.cloneDeep(item)
      this.data.splice(key, 0, {
        pond: newItem.pond,
        qty: [],
        time: this.date,
      })
      this.resetKey()
    },

    deleteData(key) {
      this.data.splice(key, 1)
      this.resetKey()
    },

    addProduct(item, key) {
      this.selectedProducts.splice(key, 0, {})
      this.resetKey()
    },

    deleteProduct(key) {
      this.selectedProducts.splice(key, 1)
      for (let i = 0; i < this.data.length; i++) {
        delete this.data[i].qty[key]
      }
      this.resetKey()
    },

    totalPondData(item) {
      return this.data.filter(e => e.pond.pond_id === item.pond.pond_id).length
    },

    resetKey() {
      this.data = this.data.map((e, key) => {
        return {
          ...e,
          key
        }
      })
    },

    handleFocus(n) {
      this.selectedInput = n
      this.maxInput = this.$refs[`input-form`].length - 1
    },

    handleArrowRight() {
      if (this.selectedInput >= this.maxInput) this.selectedInput = -1
      let find = document.querySelectorAll(`.input-form`)[this.selectedInput + 1]
      this.selectElement(find)
    },

    handleArrowLeft() {
      if (this.selectedInput <= 0) this.selectedInput = document.querySelectorAll(`.input-form`).length
      let find = document.querySelectorAll(`.input-form`)[this.selectedInput - 1]
      this.selectElement(find)
    },

    handleArrowDown() {
      if (this.selectedInput + this.maxInputForm > this.maxInput) this.selectedInput = -(this.maxInput + 1 - this.selectedInput) + this.maxInputForm
      else this.selectedInput += this.maxInputForm
      let find = document.querySelectorAll(`.input-form`)[this.selectedInput]
      this.selectElement(find)
    },

    handleArrowUp() {
      if (this.selectedInput - this.maxInputForm < 0) this.selectedInput = this.maxInput + this.selectedInput + 1
      this.selectedInput -= this.maxInputForm
      let find = document.querySelectorAll(`.input-form`)[this.selectedInput]
      this.selectElement(find)
    },

    selectElement(el) {
      if (el) {
        if (el.querySelector('input')) el.querySelector('input').focus()
        else if (el.querySelector('select')) el.querySelector('select').focus()
        else if (el.querySelector('button')) el.querySelector('button').focus()
      }
    },

    ...mapActions('farm', [
      'getWarehouse',
    ]),

    async init() {
      if (this.selectedFarm.ponds.length) {
        if (this.selectedFarm.blocks.length) this.selectedBlock = this.selectedFarm.blocks[0]

        let data = JSON.parse(localStorage.getItem('sgara.pgsql.input.probiotics-free.data'))
        let date = JSON.parse(localStorage.getItem('sgara.pgsql.input.probiotics-free.date'))
        let products = JSON.parse(localStorage.getItem('sgara.pgsql.input.probiotics-free.products'))

        // feed 5, chemical 3, prob 6

        this.$loading()
        this.products = []

        Promise.all([
          this.getWarehouse({
            farm_id: this.selectedFarm.farm_id,
            category_id: 3,
          }),
          this.getWarehouse({
            farm_id: this.selectedFarm.farm_id,
            category_id: 6,
          }),
        ]).then(res => {
          let data = []

          if (!res[0].error) data = data.concat(res[0].data)
          if (!res[1].error) data = data.concat(res[1].data)

          this.products = data
        }).finally(() => {
          this.resetForm(data, date, products)
          this.$loading(false)
        })
      }
    },

    resetForm(data = null, date = null, products = null) {
      this.data = []
      this.selectedDate = date ? new Date(date) : new Date()
      this.selectedProducts = products || []

      if (this.selectedProducts.length === 0) {
        if (this.products.length) {
          this.selectedProducts = this._.cloneDeep(this.products).map(e=> {
            return {
              category_id: e.category_id,
              product: e,
              product_name: e.product_name,
            }
          })
        }
      }

      if (this.selectedProducts.length === 0) this.selectedProducts.push({})

      if (data && data.length) {
        this.data = data.map(e => {
          e.time = new Date(e.time)
          return e
        })
      } else {
        for (let i = 0; i < this.filteredPondsInput.length; i++) {
          let find = data ? data.find(e => e.pond.pond_id === this.filteredPondsInput[i].pond_id) : null
          this.data.push({
            pond: this.filteredPondsInput[i],
            product: find ? find.product : null,
            product_name: find && find.product ? find.product.product_name : null,
            qty: find ? find.qty : [],
            time: find ? new Date(find.date) : this.date,
          })
        }
      }

      this.$nextTick(() => {
        this.$loading(false)
      })
    },
    handleSelectProduct(option, item) {
      item.product = option
      if (item.product) {
        item.product.product_name = item.product.product_name.replace(' (Add New)', '')
        item.product_name = item.product.product_name
        if (!item.product.unit) item.product.unit = 'Kg'
        item.category_id = item.product.category_id || 6
      }
      this.filteredProduct = []
    },

    async handleSubmit() {
      this.$loading()

      let data = []
      let ponds = []

      data = this.filteredData.reduce((a, b) => {
        a.push(b)
        if (!ponds.find(f => f.pond_id === b.pond.pond_id)) ponds.push(b.pond)
        return a
      }, data).map((e, key)=> {
        data.product = this.selectedProducts[key]
        return e
      })

      let newProducts = this.selectedProducts.reduce((a, b) => {
        if (b.product && b.product.product_name && !b.product.warehouse_id) {
          let find = a.find(e => b.product && e.product_name === b.product.product_name)
          if (!find) a.push({
            ...b.product,
            category_id: b.category_id,
          })
        }
        return a
      }, [])

      if (newProducts.length) {
        for (let i = 0; i < newProducts.length; i++) {
          let product = newProducts[i]
          await this.addWarehouse({
            farm_id: this.selectedFarm.farm_id,
            category_id: product.category_id,
            mode: 0,
            desc: '',
            product_name: product.product_name,
            product_price: 0,
            qty: 0,
            unit: product.unit,
            min_qty: 0,
            synthesize: false,
            component: null,
            created_at: this.$timestamp(),
          }).then((res) => {
            if (res.data && res.data.id) {
              product.warehouse_id = res.data.id
            }
          })
        }
        this.selectedProducts = this.selectedProducts.map(e => {
          if (e.product.product_name && !e.product.warehouse_id) {
            let find = newProducts.find(f => f.product_name == e.product.product_name)
            if (find) {
              e.product.warehouse_id = find.warehouse_id
            }
          }
          return e
        })
      }

      for (let i = 0; i < ponds.length; i++) {
        let cycle = await this.$store.dispatch('farm/getActiveCycle', ponds[i].pond_id)
        if (cycle) ponds[i].cycle = cycle
      }

      let payload = []

      let timediff = new Date().getTimezoneOffset() * -1

      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let pond = ponds.find(e => e.pond_id === item.pond.pond_id)
        if (pond && pond.cycle) {
          for (let j = 0; j < this.selectedProducts.length; j++) {
            let product = this.selectedProducts[j]
            let qty = item.qty[j]
            if (product.product && qty) {
              let productData = product.product
              let date = this.$timestamp(this.$dateFormat(this.selectedDate, 'YYYY-MM-DD') + ' ' + this.$timeFormat(item.time))
              payload.push({
                farm_id: productData.farm_id || this.selectedFarm.farm_id,
                pond_id: pond.pond_id,
                block_id: pond.block_id,
                cycle_id: pond.cycle.cycle_id,
                warehouse_id: productData.warehouse_id,
                component_id: 0,
                product_name: productData.product_name,
                product_price: productData.product_price || 0,
                category_id: productData.category_id,
                mode: productData.mode || 0,
                unit: productData.unit,
                created_at: date,
                date: this.$startDay(date).valueOf(),
                qty: qty,
                desc: item.desc || '',
                timediff,
              })
            }
          }
        }
      }

      let res = await this.$store.dispatch('farm/useItem', payload)

      this.$loading(false)

      if (!res.error) {
        this.$emit('close')
        this.$emit('submit')
        localStorage.removeItem('sgara.pgsql.input.probiotics-free.data')
        localStorage.removeItem('sgara.pgsql.input.probiotics-free.date')
        localStorage.removeItem('sgara.pgsql.input.probiotics-free.products')
        this.resetForm()
        this.$router.push('/farm/' + this.selectedFarm.farm_id)
      }
    },
    searchProduct(value) {
      let search = value.trim().toLowerCase()

      if (search) {

        this.filteredProduct = this.products.filter(e => e.product_name.trim().toLowerCase().includes(search)).sort((a, b) => a.product_name.localeCompare(b.product_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }))

        let find = this.filteredProduct.find(e => e.product_name.trim().toLowerCase() === search)

        if (!find) {
          this.filteredProduct = [
            {
              product_name: value.trim() + ' (Add New)',
            },
            ...this.filteredProduct,
          ]
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}
</style>

<style lang="scss">
.input-data {
  .button.is-small {
    padding: 0.05rem 0.75rem;
  }

  .select-unit {
    min-width: unset !important;
    width: 100px;
  }
}
</style>
